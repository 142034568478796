import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';

const Section = ({ title, children, color = "black" }) => (
  <motion.div
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{ backgroundColor: color }}
    className="mb-8 p-6 rounded-lg shadow-lg"
  >
    <h2 className="text-2xl font-bold mb-4 text-white">{title}</h2>
    {children}
  </motion.div>
);

const ListItem = ({ children, bgColor }) => (
  <motion.li
    whileHover={{ scale: 1.05 }}
    className="flex items-start mb-2 cursor-pointer"
  >
    <span style={{ color: bgColor === '#FE4588' ? 'black' : '#FE4588' }} className="mr-2">&#9654;</span>
    <span>{children}</span>
  </motion.li>
);

const HighlightedText = ({ children }) => (
  <span className="font-bold text-[#FFFF]">{children}</span>
);

const ImprovementChart = ({ data }) => (
  <ResponsiveContainer width="100%" height={400}>
    <BarChart data={data} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="minImprovement" name="Minimální zlepšení" fill="#000000" />
      <Bar dataKey="maxImprovement" name="Maximální zlepšení" fill="#FE4588" />
    </BarChart>
  </ResponsiveContainer>
);

const NaceneniPrezentace = () => {
  const improvementData = [
    { name: 'Prokliky', minImprovement: 20, maxImprovement: 30 },
    { name: 'Konverze', minImprovement: 15, maxImprovement: 25 },
    { name: 'Uživatelé', minImprovement: 40, maxImprovement: 60 },
    { name: 'Retence', minImprovement: 30, maxImprovement: 40 },
    { name: 'Provize', minImprovement: 35, maxImprovement: 50 },
  ];

  return (
    <div className="max-w-4xl mx-auto bg-gray-100 p-8 font-sans">
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ backgroundColor: '#FE4588' }}
        className="text-white p-6 mb-8 rounded-lg text-center shadow-lg"
      >
        <h1 className="text-4xl font-bold mb-2">SLEVY A AKCE</h1>
        <p className="text-xl">Návrh spolupráce - nacenění</p>
      </motion.div>

      <Section title="1. Mobilní aplikace (Android + iOS)">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="black">Login přes Google a Apple: 12 hodin</ListItem>
          <ListItem bgColor="black">Notifikace (všeobecné a dle kategorií): 13 hodin</ListItem>
          <ListItem bgColor="black">Zobrazení slev a kategorií (UI/UX + logika): 32 hodin</ListItem>
          <ListItem bgColor="black">Přidávání slev (UI + back-end integrace): 23 hodin</ListItem>
          <ListItem bgColor="black">Testování a optimalizace: 12 hodin</ListItem>
          <ListItem bgColor="black">Základní nastavení designu a stylu: 13 hodin</ListItem>
          <ListItem bgColor="black">Nastavení platforem (App Store, Google Play): 7 hodin</ListItem>
        </ul>
        <p className="font-bold mt-4 text-white">Celkový odhad: 112 hodin</p>
      </Section>

      <Section title="2. Backend (server + CMS)" color="#FE4588">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="#FE4588">Uživatelský management (registrace, přihlášení, role): 13 hodin</ListItem>
          <ListItem bgColor="#FE4588">Systém notifikací: 11 hodin</ListItem>
          <ListItem bgColor="#FE4588">Databáze slev (CRUD operace pro slevy a kategorie): 18 hodin</ListItem>
          <ListItem bgColor="#FE4588">CMS (administrativní rozhraní pro přidávání slev a správu uživatelů): 22 hodin</ListItem>
          <ListItem bgColor="#FE4588">Integrace s affiliate API: 18 hodin</ListItem>
          <ListItem bgColor="#FE4588">Hosting, nasazení a optimalizace serveru: 13 hodin</ListItem>
          <ListItem bgColor="#FE4588">Testování a zabezpečení: 11 hodin</ListItem>
        </ul>
        <p className="font-bold mt-4 text-white">Celkový odhad: 106 hodin</p>
      </Section>

      <Section title="3. Celkový čas">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="black">Mobilní aplikace: 112 hodin</ListItem>
          <ListItem bgColor="black">Backend: 106 hodin</ListItem>
        </ul>
        <p className="font-bold mt-4 text-white">Celkový čas: 218 hodin</p>
      </Section>

      <Section title="4. Možnosti platby" color="#FE4588">
        <h3 className="text-xl font-bold mb-4 text-white">Jednorázová platba:</h3>
        <ul className="list-none pl-0 text-white mb-6">
          <ListItem bgColor="#FE4588">Mobilní aplikace: 112 hodin × 850 Kč = 95,200 Kč</ListItem>
          <ListItem bgColor="#FE4588">Backend: 106 hodin × 850 Kč = 90,100 Kč</ListItem>
        </ul>
        <p className="font-bold mb-6 text-white">Celková cena při jednorázové platbě: 185,300 Kč</p>

        <h3 className="text-xl font-bold mb-4 text-white">Měsíční platba na rok:</h3>
        <ul className="list-none pl-0 text-white mb-6">
          <ListItem bgColor="#FE4588">Počáteční platba: 70,000 Kč</ListItem>
          <ListItem bgColor="#FE4588">11 měsíčních plateb: 25,000 Kč/měsíc</ListItem>
          <ListItem bgColor="#FE4588">Celková odhadovaná cena při měsíční platbě: 345,000 Kč</ListItem>
        </ul>
        <p className="text-white mb-4">Měsíční platba zahrnuje:</p>
        <ul className="list-none pl-0 text-white mb-6">
          <ListItem bgColor="#FE4588">Vývoj a implementaci aplikace dle specifikace</ListItem>
          <ListItem bgColor="#FE4588">IT podporu pro další firemní projekty a záležitosti (20-30 hodin měsíčně)</ListItem>
          <ListItem bgColor="#FE4588">Pravidelné aktualizace a údržbu aplikace</ListItem>
        </ul>
        <p className="text-white">Při volbě měsíční platby získáváte flexibilitu ve financování projektu a dodatečnou IT podporu pro vaše další potřeby.</p>
      </Section>

      <Section title="5. Další náklady (volitelné)">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="black">Analytické nástroje (Google Analytics, Firebase): 6 hodin × 850 Kč = 5,100 Kč</ListItem>
          <ListItem bgColor="black">Údržba a podpora (měsíčně, 15 hodin): 15 hodin × 850 Kč = 12,750 Kč</ListItem>
        </ul>
      </Section>

      <Section title="6. Potenciální vylepšení a očekávané výsledky" color="#FE4588">
        <p className="mb-4 text-white">Na základě obecně známých statistik a vašeho současného úspěchu s Instagram účtem můžeme očekávat následující vylepšení v průběhu prvního roku provozu aplikace:</p>
        <ul className="list-none pl-0 text-white mb-6">
          <ListItem bgColor="#FE4588">Zvýšení prokliků: Implementace mobilní aplikace může vést k nárůstu prokliků o <HighlightedText>20-30%</HighlightedText> díky lepší dostupnosti a push notifikacím.</ListItem>
          <ListItem bgColor="#FE4588">Vyšší konverze: Personalizované nabídky a cílené notifikace mohou zvýšit míru konverze o <HighlightedText>15-25%</HighlightedText>.</ListItem>
          <ListItem bgColor="#FE4588">Rozšíření uživatelské základny: Aplikace v App Store a Google Play může přilákat nové uživatele, potenciálně zvýšit počet sledujících o <HighlightedText>40-60%</HighlightedText> v průběhu prvního roku.</ListItem>
          <ListItem bgColor="#FE4588">Zvýšení retence: Mobilní aplikace obvykle vedou k vyšší retenci uživatelů, očekávaný nárůst o <HighlightedText>30-40%</HighlightedText> oproti webovým uživatelům.</ListItem>
          <ListItem bgColor="#FE4588">Nárůst affiliate provizí: S rozšířenou uživatelskou základnou a lepším cílením lze očekávat nárůst affiliate provizí o <HighlightedText>35-50%</HighlightedText>.</ListItem>
          <ListItem bgColor="#FE4588">Zlepšení uživatelských dat: Aplikace umožní lepší sběr a analýzu uživatelských dat, což může vést k efektivnějšímu marketingu a personalizaci.</ListItem>
        </ul>
        <div className="bg-white p-4 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-2 text-black">Graf očekávaných zlepšení v prvním roce provozu aplikace</h3>
          <p className="text-sm text-gray-600 mb-4">Tento graf znázorňuje odhadované minimální a maximální zlepšení v klíčových metrikách během prvního roku po implementaci aplikace.</p>
          <ImprovementChart data={improvementData} />
        </div>
        <p className="mt-4 text-white">Tyto odhady jsou založeny na průměrných statistikách v odvětví a mohou se lišit v závislosti na konkrétní implementaci a marketingové strategii. Skutečné výsledky mohou být ovlivněny mnoha faktory, včetně konkurence na trhu a celkové ekonomické situace.</p>
      </Section>

      <Section title="7. Doporučení pro maximalizaci přínosů">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="black">Integrace sociálních médií: Propojení aplikace s vaším úspěšným Instagram účtem pro snadné sdílení a vyšší dosah.</ListItem>
          <ListItem bgColor="black">Gamifikace: Implementace věrnostního programu nebo systému odměn pro zvýšení zapojení uživatelů.</ListItem>
          <ListItem bgColor="black">A/B testování: Pravidelné testování různých prvků aplikace pro optimalizaci uživatelského zážitku a konverzí.</ListItem>
          <ListItem bgColor="black">Pravidelné aktualizace: Plán pravidelných aktualizací aplikace s novými funkcemi na základě zpětné vazby uživatelů.</ListItem>
          <ListItem bgColor="black">Influencer marketing: Využití vašich existujících vztahů s influencery pro propagaci aplikace.</ListItem>
        </ul>
      </Section>

      <Section title="8. Shrnutí a další kroky" color="#FE4588">
        <ul className="list-none pl-0 text-white">
          <ListItem bgColor="#FE4588">Vyberte preferovanou možnost platby (jednorázová nebo měsíční)</ListItem>
          <ListItem bgColor="#FE4588">Domluvme si osobní schůzku pro detailní projednání projektu a vašich specifických potřeb</ListItem>
          <ListItem bgColor="#FE4588">Připravíme detailní plán projektu včetně milníků a termínů dodání</ListItem>
          <ListItem bgColor="#FE4588">Začneme s vývojem a budeme vás pravidelně informovat o postupu</ListItem>
        </ul>
      </Section>

      <motion.div
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ backgroundColor: '#FE4588' }}
        className="text-white p-4 mt-8 rounded-lg text-center shadow-lg"
      >
        <p className="text-xl font-bold">Budeme se těšit na spolupráci!</p>
        <p className="mt-2">Kontakt: +420 608 555 967 | mirek@mirazcode.com</p>
      </motion.div>
    </div>
  );
};

export default NaceneniPrezentace;
